<template>
  <div>
    <div
      class="mb-4 sm:mb-6 w-11/12 mr-auto ml-auto rounded-lg shadow-lg border sm:w-9/12 bg-white border-lightborder dark:bg-black200 dark:border-border100 dark:shadow"
      style="box-shadow:0 0.5rem 1.2rem rgba(82, 85, 92, .15);">
      <div class="py-3 px-2 mr-auto ml-auto">
        <div class="flex justify-start items-center flex-wrap sm:flex-nowrap px-2">
          <div class="text-black dark:text-white300 text-base font-bold sm:text-xl pr-3">{{
            $t('branch.currentBranch')
          }}:</div>
          <div class="flex justify-start items-center">
            <div :class="index !== 0 ? 'ml-2' : ''" v-for="(item, index) in branchList" :key="index">
              <el-radio @input="enabled(item)" v-model="chainid" :label="item.chainid">{{ item.name
                }}</el-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 w-11/12 sm:w-9/12 mr-auto ml-auto">
      <module-title title="HRC20" :total="totalHRC20"></module-title>
    </div>
    <div
      class=" mb-4 w-11/12 sm:w-9/12 mr-auto ml-auto rounded-lg shadow-lg border bg-white border-lightborder dark:bg-black200 dark:border-border100 dark:shadow">
      <hrc-list-table :dataList="hrcListDatas" :loadStatus="hrcListLoadStatus" />
      <div class="">
        <h-pagination @changePageSize="toHRCFirstPage" @toFirstPage="toHRCFirstPage" @toPrePage="toHRCPrePage"
          @toNextPage="toHRCNextPage" @toLastPage="toHRCLastPage" :currentPage="hrcCurrentPage" :totalPage="totalPage"
          @toTargetPage="toTradeTargetPage"></h-pagination>
      </div>
    </div>
  </div>
</template>


<script>

import ModuleTitle from '@/components/public/ModuleTitle'
import HPagination from '@/components/public/HPagination'
import HrcListTable from '@/components/child/HrcListTable'
import { listHRC20 } from '@/request/hrc';
import { numberFormat } from '../../utils/format';
import { branchList } from '@/request/branch'
export default {
  components: { HrcListTable, ModuleTitle, HPagination, },
  data() {
    return {
      hrcListDatas: [],
      hrcListLoadStatus: 'loading',
      hrcPageSize: 10,
      hrcCurrentPage: 1,
      totalHRC20: 0,
      totalPage: 1,
      branchList: [],
      chainid: 0
    }
  },
  mounted() {
    this.getListHRC20()
    this.getBranchList()
  },
  methods: {
    numberFormat,
    getBranchList() {
      branchList().then(res => {
        console.log(res)
        res.data.map(item => {
          this.branchList.push(item)
        })
        if (localStorage.getItem('chainID')) {
          this.chainid = Number(localStorage.getItem('chainID'))
        }
        console.log(this.chainid)
        this.chainid = Number(this.chainid)
        console.log(this.chainid)
      })
        .catch(err => {
          console.log(err)
        })
    },
    enabled(item) {
      console.log(item)
      localStorage.setItem('chainName', item.name)
      localStorage.setItem('chainID', item.chainid)
      localStorage.setItem('symbol', item.symbol)
      this.$store.commit('getChainId', item.chainid)
      this.$store.commit('getChainName', item.name)
      console.log(item.chainid, localStorage.getItem('chainID'), this.$store.state.chainName, item.checked)
      console.log(localStorage.getItem('chainID'))
      window.location.reload()
    },
    getListHRC20() {
      this.hrcListLoadStatus = 'loading'
      listHRC20({ pageSize: this.hrcPageSize, page: this.hrcCurrentPage, chainid: localStorage.getItem('chainID') }).then(res => {
        console.log(res)
        if (res.data.length !== 0) {
          this.hrcListDatas = res.data
          this.hrcListLoadStatus = 'finished'
        } else {
          this.hrcListLoadStatus = 'empty'
        }
        // this.totalHRC20 = this.$t('moduleTitle.totalContract', { count: numberFormat(res.total) })
        this.totalHRC20 = res.total
        this.totalPage = res.totalPage
        this.hrcCurrentPage = res.page

      });
      console.log('dataList', this.hrcListDatas);
    },

    toHRCFirstPage(selectedPageSize) {
      console.log('第一页')
      this.hrcPageSize = selectedPageSize
      this.hrcCurrentPage = 1
      this.hrcListDatas = []
      this.getListHRC20()
    },
    toHRCPrePage(selectedPageSize) {
      if (this.hrcCurrentPage === 1) {
        return
      }
      this.hrcPageSize = selectedPageSize
      this.hrcCurrentPage = this.hrcCurrentPage - 1
      this.hrcListDatas = []
      this.getListHRC20()
    },
    toHRCNextPage(selectedPageSize) {
      this.hrcPageSize = selectedPageSize
      this.hrcCurrentPage = this.hrcCurrentPage + 1
      this.hrcListDatas = []
      this.getListHRC20()
    },
    toHRCLastPage(selectedPageSize) {
      console.log(this.hrcCurrentPage, this.totalPage)
      if (this.hrcCurrentPage >= this.totalPage) {
        return
      }
      this.hrcPageSize = selectedPageSize
      this.hrcCurrentPage = this.totalPage
      this.hrcListDatas = []
      this.getListHRC20()
    },
    toTradeTargetPage(selectedPageSize, targetPage) {
      console.log(targetPage)
      if (targetPage <= 0) {
        return
      }
      this.hrcPageSize = selectedPageSize
      this.hrcCurrentPage = targetPage
      this.hrcListDatas = []
      this.getListHRC20()
    },
  }
}
</script>

<style>
.target-item {
  cursor: pointer;
}

.first-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item {
  display: inline-block;
}

.big-item {
  display: inline-block;
  width: 500px;
}

.owner {
  margin-left: 80px;
}

.votes {
  margin-left: 80px;
}

.item-all {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>